<template>
  <div class="" v-if="liquidation">
    <TopBarTitleComponent
      title="Información Liquidación de Compra"
      :showBackIcon="true"
    >
      <template slot="right-area">
        <div class="d-flex justify-content-end align-items-center">
          <el-dropdown
            @command="handleCommand"
            class="dropdown-theme-gray-outline d-flex align-items-center mr-4"
          >
            <span class="el-dropdown-link">
              <span class="ml-2">Opciones</span>
              <i class="el-icon-arrow-down el-icon--right mr-2"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <template
                v-if="
                  liquidation.status === 'draft' ||
                    liquidation.status === 'failed'
                "
              >
                <el-dropdown-item command="liquidation-edit"
                  ><img :src="iconEdit" alt="" /> Editar</el-dropdown-item
                >
              </template>
              <template v-if="liquidation.status === 'success'">
                <el-dropdown-item command="liquidation-nuled"
                  ><img :src="iconNuled" alt="" /> Anular
                  liquidación</el-dropdown-item
                >
              </template>

              <el-dropdown-item
                v-if="
                  liquidation.status !== 'success' &&
                    liquidation.status !== 'nuled'
                "
                command="liquidation-delete"
              >
                <img :src="iconDelete" alt="" /> Eliminar
              </el-dropdown-item>

              <el-dropdown-item command="liquidation-send">
                <img :src="iconSend" alt="" /> Enviar
                documento</el-dropdown-item
              >

              <!-- <el-dropdown-item command="liquidation-history-email">
                <img :src="iconHistory" alt="" /> Historial
                envio</el-dropdown-item
              > -->

              <el-dropdown-item command="liquidation-download"
                ><img :src="iconDownload" alt="" /> Descargar
                PDF</el-dropdown-item
              >
              <el-dropdown-item command="liquidation-paid"
                ><img :src="iconCheck" alt="" /> {{ textOptionIsPaid }} como
                pagada</el-dropdown-item
              >
            </el-dropdown-menu>
          </el-dropdown>
          <ButtonPrimaryComponent
            v-if="
              liquidation.status !== 'success' && liquidation.status !== 'nuled'
            "
            title="Firmar"
            @onSaveClick="toogleModalLiquidationSRI"
          />
        </div>
      </template>
    </TopBarTitleComponent>
    <b-container fluid class="pb-4 px-lg-4 pt-4">
      <b-row v-if="liquidation.sri_sign_message">
        <b-col>
          <AlertInfoComponent
            title="Notificación SRI"
            type="error"
            class="mb-4"
            :description="liquidation.sri_sign_message"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <div class="card box-shadow card-quotes-view py-4">
            <div class="card-body">
              <div class="card-quotes-view-header">
                <b-row>
                  <b-col xs="12" sm="12" md="12" lg="6">
                    <div class="card-quotes-view-logo mb-4">
                      <b-row>
                        <b-col xs="12" sm="12" md="5" lg="4">
                          <div class="logo">
                            <business-logo />
                          </div>
                        </b-col>
                        <b-col xs="12" sm="12" md="7" lg="8">
                          <div class="info">
                            <h4 class="h4 font-bold m-0">
                              N° {{ liquidation.number_liquidation }}
                            </h4>
                            <h2 class="h2 font-bold m-0"></h2>
                            <p class="font-semi-bold mt-2">
                              {{ getUser.user_business.social_reason }}
                            </p>
                            <p class="font-semi-bold">
                              RUC: {{ getUser.user_business.business_ruc }}
                            </p>
                            <p class="font-semi-bold">
                              {{ getUser.user_business.business_address }}
                            </p>
                          </div>
                          <SignAuthorizationInfo
                            class="mt-4"
                            :accessKey="liquidation.access_key"
                            :signMode="liquidation.sign_mode"
                          />
                        </b-col>
                      </b-row>
                    </div>
                  </b-col>
                  <b-col xs="12" sm="12" md="12" lg="6">
                    <div class="card-quotes-view-info">
                      <b-row>
                        <b-col xs="12" sm="4" md="4" lg="4">
                          <label class="font-semi-bold d-block"
                            >Fecha de emisión</label
                          >
                          <p class="m-0">
                            {{ liquidation.emission_date_for_view }}
                          </p>
                        </b-col>
                        <b-col xs="12" sm="4" md="4" lg="4">
                          <label class="font-semi-bold d-block"
                            >Punto de emisión</label
                          >
                          <p
                            class="m-0"
                            v-if="liquidation.user_business_e_point_id"
                          >
                            {{ liquidation.emission_point.code }}
                          </p>
                        </b-col>
                        <b-col xs="12" sm="4" md="4" lg="4">
                          <label class="font-semi-bold d-block mb-2"
                            >Status</label
                          >
                          <StatusReadDocumentsComponent
                            :status.sync="liquidation.status"
                          />
                        </b-col>
                      </b-row>
                      <b-row class="mt-4">
                        <b-col xs="12" sm="4" md="4" lg="4">
                          <label class="font-semi-bold d-block"
                            >Proveedor</label
                          >
                          <p class="m-0">{{ liquidation.client.name }}</p>
                        </b-col>
                        <b-col xs="12" sm="4" md="4" lg="4">
                          <label class="font-semi-bold d-block"
                            >Documento</label
                          >
                          <p class="m-0">{{ liquidation.client.document }}</p>
                        </b-col>
                        <b-col xs="12" sm="4" md="4" lg="4">
                          <label class="font-semi-bold d-block">Correo</label>
                          <p class="m-0">
                            {{ liquidation.client.email }}
                          </p>
                        </b-col>
                      </b-row>
                    </div>
                  </b-col>
                </b-row>
              </div>
              <div class="card-quotes-view-body mt-5">
                <div class="card-quotes-view-body-products">
                  <div class="card-quotes-view-table table-items">
                    <b-row class="table-items-header">
                      <b-col xs="12" sm="12" md="1">
                        <h6 class="h6 font-bold mb-0 ml-2">Código</h6>
                      </b-col>
                      <b-col xs="12" sm="12" md="5">
                        <h6 class="h6 font-bold mb-0">Detalle</h6>
                      </b-col>
                      <b-col xs="12" sm="4" md="2">
                        <h6 class="h6 font-bold mb-0">Cantidad</h6>
                      </b-col>
                      <b-col xs="12" sm="4" md="2">
                        <h6 class="h6 font-bold mb-0">Precio U.</h6>
                      </b-col>
                      <b-col xs="12" sm="4" md="2">
                        <h6 class="h6 font-bold mb-0">Subtotal</h6>
                      </b-col>
                    </b-row>
                  </div>

                  <b-row
                    class="mt-4 d-flex"
                    v-for="(product, index) in liquidation.products"
                    :key="index"
                  >
                    <b-col xs="12" sm="12" md="1">
                      <p class="m-0 ml-2">{{ product.product.code }}</p>
                    </b-col>
                    <b-col xs="12" sm="12" md="5">
                      <p class="m-0">
                        <strong>{{ product.product.name }}</strong> <br />
                        <br />
                        <span
                          v-html="product.product_detail"
                          class="text-pre-wrap"
                        ></span>
                      </p>
                    </b-col>
                    <b-col xs="12" sm="4" md="2">
                      <p class="m-0">{{ product.product_quantity }}</p>
                    </b-col>
                    <b-col xs="12" sm="4" md="2">
                      <p class="m-0">${{ product.product_amount }}</p>
                    </b-col>
                    <b-col xs="12" sm="4" md="2">
                      <p class="m-0">
                        ${{
                          handleCalculateSubTotal(
                            product.product_quantity,
                            product.product_amount
                          )
                        }}
                      </p>
                    </b-col>
                  </b-row>
                </div>
                <hr />
                <b-row>
                  <b-col xs="12" sm="12" md="6">
                    <h5 class="h5 font-semi-bold d-block mb-2">
                      Forma de pago
                    </h5>
                    {{ liquidation.sri_forms_payment.title }}

                    <h5 class="h5 font-semi-bold d-block mt-4">
                      Información adicional
                    </h5>
                    <b-row
                      v-for="(additional,
                      index) in liquidation.additional_extras"
                      :key="index"
                    >
                      <b-col>{{
                        `${additional.name} - ${additional.description}`
                      }}</b-col>
                    </b-row>
                  </b-col>
                  <b-col xs="12" sm="12" md="4" offset-md="2"
                    ><DocumentCalculator
                      readOnly
                      :setProducts="liquidation.products"
                      :setDisc="liquidation.discount"
                      :setServ="liquidation.service"
                    />
                  </b-col>
                </b-row>
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-container>

    <el-dialog
      width="35%"
      title=""
      :visible.sync="liquidationSRIOuterVisible"
      class=""
    >
      <div class="wrapper__invoice-signature">
        <h4 class="h4 text-center font-semi-bold text-break text-color-primary">
          ¿Desea firmar y enviar el documento al SRI?
        </h4>
        <div class="d-flex justify-content-center align-items-center mt-5">
          <button
            type="button"
            class="btn md__btn-third-secondary mr-4"
            @click="toogleModalLiquidationSRI"
          >
            Cancelar
          </button>
          <ButtonPrimaryComponent
            title="Confirmar"
            @onSaveClick="sendLiquidationSRI"
          />
        </div>
      </div>
    </el-dialog>

    <el-dialog
      width="35%"
      title=""
      :visible.sync="liquidationNuledOuterVisible"
      class=""
    >
      <div class="wrapper__invoice-nuled text-center">
        <img :src="icoNotifyDanger" alt="ico danger" width="30" />
        <h4 class="h4 font-semi-bold text-break text-color-primary mt-4">
          ¿Seguro desea anular la liquidación?
        </h4>

        <p class="text-color-primary">
          Para completar todo el proceso de anulación la liquidación debe ser
          anulada en el
          <LinkSRI />
        </p>

        <div class="d-flex justify-content-center align-items-center mt-4">
          <button
            @click="toggleModalLiquidationNuled"
            class="btn md__btn-secondary mr-4"
          >
            Cancelar
          </button>
          <ButtonPrimaryComponent
            title="Anular"
            @onSaveClick="onLiquidationNuled"
          />
        </div>
      </div>
    </el-dialog>

    <el-dialog
      width="35%"
      title=""
      :visible.sync="liquidationDeleteOuterVisible"
      class=""
    >
      <div class="wrapper__invoice-delete text-center">
        <img :src="icoNotifyDanger" alt="ico danger" width="30" />
        <h4 class="h4 font-semi-bold text-break text-color-primary mt-4">
          ¿Seguro desea eliminar esta liquidación de compra?
        </h4>

        <p class="text-color-primary text-break">
          Recuerde que solo puede eliminar las liquidaciones de compras en
          borrador.
        </p>

        <div class="d-flex justify-content-center align-items-center mt-4">
          <button
            @click="toggleModalLiquidationDelete"
            class="btn md__btn-secondary mr-4"
          >
            Cancelar
          </button>
          <ButtonPrimaryComponent
            title="Eliminar"
            @onSaveClick="onLiquidationDelete()"
          />
        </div>
      </div>
    </el-dialog>

    <WrapperSendDocumentDrawer
      @onDocumentSend="onDocumentSend"
      @onCloseDrawer="onCloseDocumentSendDrawer"
      :visibleDocumentSend="visibleDocumentSend"
      :toMails="toMails"
      :isLoadingConfigTexts="false"
    />
  </div>
</template>

<script>
import BusinessLogo from "@/components/Business/BusinessLogo";
import AlertInfoComponent from "@/components/Alerts/Info";
import TopBarTitleComponent from "../../../../components/TopBarTitle";
import liquidationService from "../services/liquidationService";
import ButtonPrimaryComponent from "../../../../components/ButtonPrimary";
import StatusReadDocumentsComponent from "../../../../components/StatusReadDocuments";
import SignAuthorizationInfo from "../../../components/SignAuthorizationInfo";
import WrapperSendDocumentDrawer from "../../../../components/WrapperSendDocumentDrawer";
import DocumentCalculator from "../../../components/DocumentCalculator";
import LinkSRI from "@/components/General/LinkSRI";

import { parseFloatFormat } from "@/assets/utils/format";
import {
  icoNotifyDanger,
  iconEdit,
  iconDelete,
  iconNuled,
  iconSend,
  iconHistory,
  iconDownload,
  iconCheck
} from "@/services/resources";
import { downloadFile } from "@/assets/utils/files";

export default {
  name: "LiquidationViewPage",
  data: () => ({
    icoNotifyDanger,
    iconEdit,
    iconDelete,
    iconNuled,
    iconSend,
    iconHistory,
    iconDownload,
    iconCheck,
    liquidationSRIOuterVisible: false,
    liquidationNuledOuterVisible: false,
    liquidationDeleteOuterVisible: false,
    visibleDocumentSend: false,
    visibleDocumentHistoryEmail: false,
    liquidation: null
  }),
  computed: {
    getUser() {
      return this.$store.getters.getUser;
    },
    toMails() {
      const { client } = this.liquidation;
      if (client && client.email) return [client.email];
      return [];
    },
    textOptionIsPaid() {
      const { is_paid } = this.liquidation;
      return is_paid ? "Desmarcar" : "Marcar";
    }
  },
  methods: {
    handleCalculateSubTotal(quantity, price) {
      return parseFloatFormat(quantity * price);
    },
    goPage(name) {
      this.$router.push({ name });
    },
    toogleModalLiquidationSRI() {
      this.liquidationSRIOuterVisible = !this.liquidationSRIOuterVisible;
    },
    async sendLiquidationSRI() {
      this.$store.dispatch("toggleRequestLoading", {
        text:
          "Por favor espere un momento, su documento esta siendo enviado al SRI."
      });
      try {
        this.toogleModalLiquidationSRI();
        let response = await liquidationService
          .sendLiquidationSRI({
            user_business_liquidation_id: this.liquidation.id
          })
          .finally(() => {
            this.$store.dispatch("toggleRequestLoading");
          });
        const { success, message, data } = response.data;
        if (success) {
          this.$notifications.success({
            message
          });
        } else {
          this.$notifications.error({
            message
          });
        }
        this.liquidation = data;
      } catch (error) {
        return false;
      }
    },
    async getLiquidation(liquidationId) {
      this.$store.dispatch("toggleRequestLoading");
      try {
        let response = await liquidationService
          .getLiquidationById(liquidationId)
          .finally(() => {
            this.$store.dispatch("toggleRequestLoading");
          });
        if (response) {
          this.liquidation = response.data.data;
        }
      } catch (error) {
        return false;
      }
    },
    async onLiquidationNuled() {
      try {
        this.$store.dispatch("toggleRequestLoading");
        const { id: liquidationId } = this.liquidation;
        let response = await liquidationService
          .updateLiquidationStatus({
            user_business_liquidation_id: liquidationId
          })
          .finally(() => {
            this.$store.dispatch("toggleRequestLoading");
            this.toggleModalLiquidationNuled();
          });
        const { success, data, message } = response.data;
        if (success) {
          this.liquidation = data;
          this.$notifications.success({
            message
          });
        }
      } catch (error) {
        return false;
      }
    },
    async onLiquidationDelete() {
      this.$store.dispatch("toggleRequestLoading");
      try {
        const { id: liquidationId } = this.liquidation;
        let response = await liquidationService
          .deleteLiquidationById(liquidationId)
          .finally(() => {
            this.$store.dispatch("toggleRequestLoading");
            this.toggleModalLiquidationDelete();
          });

        const { success, message } = response.data;

        if (success) {
          this.$notifications.success({
            message
          });
          this.goPage("sales.documents");
        }
      } catch (error) {
        return false;
      }
    },
    handleCommand(command) {
      switch (command) {
        case "liquidation-nuled":
          this.toggleModalLiquidationNuled();
          break;
        case "liquidation-edit":
          this.goPage("sales.liquidations.edit", {
            id: this.liquidation.id
          });
          break;
        case "liquidation-send":
          this.visibleDocumentSend = true;
          break;
        case "liquidation-history-email":
          this.visibleDocumentHistoryEmail = true;
          break;
        case "liquidation-delete":
          this.toggleModalLiquidationDelete();
          break;
        case "liquidation-download":
          this.liquidationDownload();
          break;
        case "liquidation-paid":
          this.liquidationPaid();
          break;
      }
    },
    async LiquidationPaid() {
      this.$store.dispatch("toggleRequestLoading");
      try {
        const { is_paid, id: liquidationId } = this.liquidation;
        let response = await liquidationService
          .liquidationPaid({
            user_business_liquidation_id: liquidationId,
            is_paid: !is_paid
          })
          .finally(() => {
            this.$store.dispatch("toggleRequestLoading");
          });
        const { success, message, data } = response.data;
        if (success) {
          this.liquidation = data;
          this.$notifications.success({
            message
          });
        }
      } catch (error) {
        return false;
      }
    },
    async liquidationDownload() {
      this.$store.dispatch("toggleRequestLoading");
      try {
        const { number_liquidation, id } = this.liquidation;
        let response = await liquidationService
          .liquidationDownloadPdf({
            id
          })
          .finally(() => {
            this.$store.dispatch("toggleRequestLoading");
          });
        const { data } = response;
        if (data) {
          downloadFile(data, `Liquidacion - #${number_liquidation}`);
        }
      } catch (error) {
        return false;
      }
    },
    async sendLiquidationEmail({ data }) {
      this.$store.dispatch("toggleRequestLoading");
      try {
        let response = await liquidationService
          .sendLiquidationEmail({
            data
          })
          .finally(() => {
            this.$store.dispatch("toggleRequestLoading");
          });
        const { success, message } = response.data;
        if (success) {
          this.visibleDocumentSend = false;
          this.$notifications.success({
            message
          });
        }
      } catch (error) {
        return false;
      }
    },
    toggleModalLiquidationNuled() {
      this.liquidationNuledOuterVisible = !this.liquidationNuledOuterVisible;
    },
    toggleModalLiquidationDelete() {
      this.liquidationDeleteOuterVisible = !this.liquidationDeleteOuterVisible;
    },
    onCloseDocumentSendDrawer() {
      this.visibleDocumentSend = false;
    },
    onCloseDocumentSendEmailHistory() {
      this.visibleDocumentHistoryEmail = false;
    },
    onDocumentSend({ data }) {
      data.append("liquidation_id", this.liquidation.id);

      this.sendLiquidationEmail({ data });
    }
  },
  components: {
    TopBarTitleComponent,
    AlertInfoComponent,
    ButtonPrimaryComponent,
    StatusReadDocumentsComponent,
    SignAuthorizationInfo,
    WrapperSendDocumentDrawer,
    DocumentCalculator,
    BusinessLogo,
    LinkSRI
  },
  mounted() {
    const { id: liquidationId } = this.$route.params;
    if (liquidationId) {
      this.getLiquidation(liquidationId);
    }
  }
};
</script>

<style lang="scss" scoped>
.card-quotes-view {
  .card-quotes-view-header {
    .card-quotes-view-logo {
      p {
        margin: 0;
      }
    }
  }
  .card-quotes-view-body-products {
    min-height: 300px;
  }
}
</style>
